import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
import LocalTv from "@/views/LocalTv.vue";

import NotFound from "@/views/NotFoundPage.vue";

const routes = [
  {
    path: "/localtv",
    name: "Local TV",
    component: LocalTv,
    meta: { requireAuth: true }
  },
  {
    path: "/",
    redirect: "login",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () =>
          import("../views/Dashboard.vue"),
        meta: { requireAuth: true }
      },
      //Sales
      {
        path: "/customer",
        name: "Customer",
        component: () =>
          import("../views/Sales/Customer.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/so",
        name: "SO",
        component: () =>
          import("../views/Sales/SO.vue"),
        props: { shipping: false },
        meta: { requireAuth: true }
      },
      {
        path: "/onlineorder",
        name: "Online Order",
        component: () =>
          import("../views/Sales/OnlineOrder.vue")
      },
      //Purchase
      {
        path: "/vendor",
        name: "Vendor",
        component: () =>
          import("../views/Purchase/Vendor.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/po",
        name: "PO",
        component: () =>
          import("../views/Purchase/PO.vue"),
        meta: { requireAuth: true }
      },
      //POS
      {
        path: "/vip",
        name: "VIP",
        component: () =>
          import("../views/POS/VIP.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/vipsetting",
        name: "VIP Setting",
        component: () =>
          import("../views/POS/VIPSetting.vue"),
        meta: { requireAuth: true }
      },
      // Inventory
      {
        path: "/inventory",
        name: "Inventory",
        component: () =>
          import("../views/Inventory/Inventory.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/receiving",
        name: "Receiving",
        component: () =>
          import("../views/Inventory/Receiving.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/shipping",
        name: "Shipping",
        component: () =>
          import("../views/Sales/SO.vue"),
        props: { shipping: true },
        meta: { requireAuth: true }
      },

      {
        path: "/inbound",
        name: "Inbound",
        component: () =>
          import("../views/Inventory/Inbound.vue"),
        meta: { requireAuth: true }
      },

      {
        path: "/outbound",
        name: "Outbound",
        component: () =>
          import("../views/Inventory/Outbound.vue"),
        meta: { requireAuth: true }
      },

      {
        path: "/stock_take",
        name: "Stock Take",
        component: () =>
          import("../views/Inventory/StockTake.vue"),
        meta: { requireAuth: true }
      },

      {
        path: "/s_n_management",
        name: "S/N Management",
        component: () =>
          import("../views/Inventory/SNManagement.vue"),
        meta: { requireAuth: true }
      },

      {
        path: "/transfer",
        name: "Transfer",
        component: () =>
          import("../views/Inventory/Transfer.vue"),
        meta: { requireAuth: true }
      },

      {
        path: "/store",
        name: "Store",
        component: () =>
          import("../views/Store/Store.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/license",
        name: "license",
        component: () =>
          import("../views/Store/License.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/table",
        name: "Table",
        component: () =>
          import("../views/Store/Table.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/menu",
        name: "Menu",
        component: () =>
          import("../views/Store/Menu.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/store_product",
        name: "Soldout",
        component: () =>
          import("../views/Store/StoreProduct.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/printer",
        name: "Printer",
        component: () =>
          import("../views/Store/Printer.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/tax",
        name: "Tax",
        component: () =>
          import("../views/Store/Tax.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/staff",
        name: "Staff",
        component: () =>
          import("../views/Store/Staff.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/employee",
        name: "employee",
        component: () =>
          import("../views/Store/Employee.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/possetting",
        name: "POS Setting",
        component: () =>
          import("../views/Store/PosSetting.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/paysetting",
        name: "Payment Setting",
        component: () =>
          import("../views/Store/PaySetting.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/setting",
        name: "setting",
        component: () =>
          import("../views/Store/Setting.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/tax_type",
        name: "tax type",
        component: () =>
          import("../views/Product/TaxType.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/department",
        name: "Department",
        component: () =>
          import("../views/Product/Department.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/category",
        name: "Category",
        component: () =>
          import("../views/Product/Category.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/product",
        name: "Product",
        component: () =>
          import("../views/Product/Product.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/item_menu",
        name: "Menu Item",
        component: () =>
          import("../views/Product/Product_2.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/bom",
        name: "BOM",
        component: () =>
          import("../views/Product/Bom.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/modifier",
        name: "Modifier",
        component: () =>
          import("../views/Product/Modifier.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/modifier_item",
        name: "modifier_item",
        component: () =>
          import("../views/Product/ModifierItem.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/unit_manage",
        name: "Unit Manage",
        component: () =>
          import("../views/Product/UnitManage.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/icons",
        name: "icons",
        component: () =>
          import("../views/Icons.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/profile",
        name: "profile",
        component: () =>
          import(
            "../views/Pages/UserProfile.vue"
          ),
        meta: { requireAuth: true }
      },
      {
        path: "/tables",
        name: "tables",
        component: () =>
          import("../views/RegularTables.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/import",
        name: "Import / Export",
        component: () =>
          import("../views/Store/Import.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/happyhour",
        name: "Happy Hour",
        component: () =>
          import("../views/Happyhour/HappyHour.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/quicknotes",
        name: "Quick Notes",
        component: () =>
          import("../views/Store/QuickNotes.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/cloudStore",
        name: "Cloud Store",
        component: () =>
          import("../views/Store/CloudStore.vue")
      },
      {
        path: "/delivery",
        name: "Delivery",
        component: () =>
          import("../views/Delivery/Delivery.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/sales",
        name: "Sales",
        component: () =>
          import("../views/Report/Sales.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/pos_sales_by_product",
        name: "Sales By Product",
        component: () =>
          import("../views/Report/Pos_sales_by_product.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/pos_sales_by_category",
        name: "Sales By Category",
        component: () =>
          import("../views/Report/Pos_sales_by_category.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/pos_sales_report_by_category",
        name: "POS Sales Report By Category",
        component: () =>
          import("../views/Report/Pos_sales_report_by_category.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/pos_day_end_report",
        name: "Day End Report",
        component: () =>
          import("../views/Report/Pos_day_end_report.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/in_out_report",
        name: "Attendance Report",
        component: () =>
          import("../views/Report/In_out_report.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/pos_transaction_report",
        name: "POS Transaction",
        component: () =>
          import("../views/Report/Pos_transaction_report.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/vip_sales",
        name: "VIP Sales",
        component: () =>
          import("../views/Report/Vip_sales.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/vip_reload",
        name: "VIP Reload",
        component: () =>
          import("../views/Report/Vip_reload.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/so_report",
        name: "SO Report",
        component: () =>
          import("../views/Report/SO_report.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/so_order_summary",
        name: "SO Order Summary",
        component: () =>
          import("../views/Report/SO_order_summary.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/statement_by_customer",
        name: "Statement By Customer",
        component: () =>
          import("../views/Report/Statement_by_customer.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/purchase_summary",
        name: "Purchase Summary",
        component: () =>
          import("../views/Report/Purchase_summary.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/purchase_item",
        name: "Purchase Item",
        component: () =>
          import("../views/Report/Purchase_item.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/inventory_summary",
        name: "Inventory Summary",
        component: () =>
          import("../views/Report/Inventory_summary.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/modifier_report",
        name: "Modifier Report",
        component: () =>
          import("../views/Report/Modifier_report.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/left_menu",
        name: "Left Menu",
        component: () => import("../views/Store/LeftMenu.vue"),
        meta: { requireAuth: true }
      },
      {
        path: "/commingsoon",
        name: "Comming Soon",
        component: () =>
          import("../views/Store/CommingSoon.vue"),
        meta: { requireAuth: true }
      }
    ]
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import("../views/Pages/Login.vue")
      },
      // {
      //   path: "/register",
      //   name: "register",
      //   component: () =>
      //     import(/* webpackChunkName: "demo" */ "../views/Pages/Register.vue")
      // },
      { path: "*", component: NotFound }
    ]
  },
];

export default routes;
